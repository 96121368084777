import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				A propos de nous - A propos de Cozy Cruiser Car Rentals
			</title>
			<meta name={"description"} content={"Chaque kilomètre, un souvenir - Faites l'expérience du voyage avec Cozy Cruiser"} />
			<meta property={"og:title"} content={"A propos de nous - A propos de Cozy Cruiser Car Rentals"} />
			<meta property={"og:description"} content={"Chaque kilomètre, un souvenir - Faites l'expérience du voyage avec Cozy Cruiser"} />
			<meta property={"og:image"} content={"https://loirevalley.live/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://loirevalley.live/images/08dbb44b.svg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://loirevalley.live/images/08dbb44b.svg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://loirevalley.live/images/08dbb44b.svg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://loirevalley.live/images/08dbb44b.svg"} />
			<link rel={"apple-touch-startup-image"} href={"https://loirevalley.live/images/08dbb44b.svg"} />
			<meta name={"msapplication-TileImage"} content={"https://loirevalley.live/images/08dbb44b.svg"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://loirevalley.live/images/3.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Наша сутність
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					У Cosy Cruiser Car Rentals ми більше, ніж просто надаємо послуги з оренди автомобілів – ми є вашим партнером у створенні незабутніх подорожей. Ми віримо, що кожна поїздка, коротка чи тривала, має бути досвідом, наповненим комфортом, надійністю та часткою особистої уваги.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Історія Cozy Cruiser
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Cosy Cruiser Car Rentals народився з простої ідеї: зробити оренду автомобіля не лише необхідністю, але й родзинкою вашої подорожі. Ми розуміємо, що подорож так само важлива, як і пункт призначення. Наш парк комфортабельних, доглянутих транспортних засобів демонструє нашу прихильність до ваших вражень від подорожей, гарантуючи, що кожна подорож, яку ви вирушаєте з нами, є історією, яку варто розповісти.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://loirevalley.live/images/4.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section background="#f9f2f0" padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://loirevalley.live/images/5.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					md-width="100%"
					object-position="0%"
					max-height="900p"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="50px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 900 42px/1.2 --fontFamily-mono">
						Чому наша сутність важлива
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						margin="50px 0px 0px 0px"
						grid-gap="16px 24px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box min-width="100px" min-height="100px" padding="25px 25px 25px 25px" background="#ffffff">
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									01
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Прагнення до комфорту: кожен транспортний засіб у нашому автопарку вибирається з урахуванням його комфорту та надійності, щоб ваша подорож була якомога приємнішою.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									02
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Підхід, орієнтований на клієнта: ми віримо в персоналізацію вашого досвіду. Наша команда завжди готова зробити все можливе, щоб задовольнити ваші потреби.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									03
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Безпека перш за все: Ваша безпека є нашим головним пріоритетом. Кожен автомобіль проходить ретельну перевірку та технічне обслуговування, щоб гарантувати вам безтурботну поїздку.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									04
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Екологічно чистий вибір: Відображаючи нашу прихильність до навколишнього середовища, ми пропонуємо цілий ряд екологічно чистих транспортних засобів, які зроблять вашу подорож комфортною та екологічною.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Створіть історію подорожі
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					З Cozy Cruiser кожна поїздка стає можливістю створити незабутні спогади. Від моменту, коли ви забираєте свій автомобіль, до моменту, коли ви його повертаєте, ми прагнемо зробити вашу поїздку гладкою та приємною.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://loirevalley.live/images/6.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});